import React, { forwardRef } from 'react';
import { useScopedController } from 'react-hook-smartform';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';

const Select = forwardRef(({
  name: fieldName,
  rules,
  label,
  helperText: defaultHelperText,
  children,
  ...other
}, ref) => {
  const { field, fieldState: { invalid, error } } = useScopedController({
    name: fieldName,
    rules,
  });

  const helperText = invalid
    ? error.message
    : defaultHelperText;

  return (
    <FormControl variant="outlined" error={!!error} fullWidth {...other}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...field}
        label={label}
        ref={ref}
      >
        {children}
      </MuiSelect>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
});

export default Select;
