import {
  AppBar,
  Grid,
  makeStyles,
  IconButton,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';

import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import useTransactions from '../../util/useTransactions';
import TransactionForm from '../TransactionForm';
import TransactionsTable from '../TransactionsTable';
import CurrencySettings from '../CurrencySettings';
import AccountSettings from '../AccountSettings';
import useAppData from '../../util/useAppData';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  hidden: {
    display: 'none',
  }
})

const downloadJson = data => async () => {
  const fileName = 'export';
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type:'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const DownloadButton = () => {
  const {
    transactions,
    accounts,
    currencies,
  } = useAppData();

  const handleClick = downloadJson({
    transactions,
    accounts,
    currencies,
  });

  return (
    <IconButton onClick={handleClick}>
      <GetAppIcon />
    </IconButton>
  );
}

const OpenFileButton = () => {
  const inputRef = useRef();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  const { setState } = useAppData();

  useEffect(() => {
    inputRef.current.addEventListener('change', function() {
      const reader = new FileReader();
      const [file] = this.files;

      reader.addEventListener('loadstart', function() {
        setDisabled(true);
      });

      reader.addEventListener('load', function(e) {
        try {
          const {
            currencies = [],
            transactions = [],
            accounts = [],
          } = JSON.parse(e.target.result);

          setState({
            currencies,
            transactions,
            accounts,
          });
        } catch (e) {
          // TODO
        } finally {
          setDisabled(false);
        }
      });

      reader.readAsText(file);
    });
  });

  return (
    <>
      <IconButton component="label" htmlFor="file-opener" disabled={disabled}>
        <OpenInBrowserIcon />
      </IconButton>
      <input type="file" ref={inputRef} accept="application/json" id="file-opener" className={classes.hidden} />
    </>
  );
}


const FifoCounter = () => {
  const {
    transactions,
    remove,
  } = useTransactions();
  const { t } = useTranslation();

  const classes = useStyles();
  const [defaultValue, setDefaultValueState] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (_, value) => setSelectedTab(value);

  const setDefaultValue = value => {
    setDefaultValueState(value);
    setSelectedTab(0);
  }

  return (
    <Grid container className={classes.container} wrap="nowrap">
      <Grid item className={clsx(classes.column, classes.grow)} component={Paper} square>
        <TransactionsTable
          transactions={transactions}
          copy={setDefaultValue}
          remove={remove}
        />
      </Grid>
      <Grid item xs={3} className={clsx(classes.column, 'no-print')}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <OpenFileButton />
              </Grid>
              <Grid item>
                <DownloadButton />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            {selectedTab === 0 ? (<TransactionForm defaultValue={defaultValue} />) : null}
            {selectedTab === 1 ? (<AccountSettings />) : null}
            {selectedTab === 2 ? (<CurrencySettings />) : null}
          </Grid>
          <Grid item>
            <AppBar position="static">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
              >
                <Tab label={t('transactions.transactions')} />
                <Tab label={t('accounts.accounts')} />
                <Tab label={t('currencies.currencies')} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FifoCounter;