import { useScopedController } from 'react-hook-smartform';
import MuiTextField from '@material-ui/core/TextField';
import React, { forwardRef } from 'react';

const TextField = forwardRef((props, ref) => {
  const {
    name: fieldName,
    helperText,
    rules,
    ...other
  } = props;

  const { field, fieldState } = useScopedController({
    name: fieldName,
    rules,
  });

  const required = !!(rules || {}).required;
  const { invalid, error } = fieldState;

  return (
    <MuiTextField
      ref={ref}
      fullWidth
      variant="outlined"
      required={required}
      error={invalid}
      helperText={
        invalid
          ? error.message
          : helperText
      }
      {...other}
      {...field}
    />
  );
});

export default TextField;
