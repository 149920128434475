import {
  makeStyles,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import displayDateTime from '../../util/displayDateTime';
import displayAmount from '../../util/displayAmount';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  win: {
    color: theme.palette.success.main,
  },
  loss: {
    color: theme.palette.error.main,
  }
}));

const TransactionsTable = ({ transactions, copy, remove }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const paintResult = result => {
    if (result && (result !== 0)) {
      return result < 0
        ? classes.loss
        : classes.win;
    }
  }

  return (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('transactions.account')}</TableCell>
          <TableCell>{t('transactions.currency')}</TableCell>
          <TableCell>{t('transactions.timestamp')}</TableCell>
          <TableCell>{t('transactions.type')}</TableCell>
          <TableCell align="right">{t('transactions.amount')}</TableCell>
          <TableCell align="right">{t('transactions.price')}</TableCell>
          <TableCell align="right">{t('transactions.total')}</TableCell>
          <TableCell align="right">{t('transactions.balance')}</TableCell>
          <TableCell align="right">{t('transactions.purchasePrice')}</TableCell>
          <TableCell align="right">{t('transactions.result')}</TableCell>
          <TableCell className="no-print" />
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((txn, i) => (
          <TableRow key={i}>
            <TableCell>{txn.account}</TableCell>
            <TableCell>{txn.currency}</TableCell>
            <TableCell>{displayDateTime(txn.timestamp)}</TableCell>
            <TableCell>{t(`types.${txn.type}`)}</TableCell>
            <TableCell align="right">{displayAmount(txn.amount)}</TableCell>
            <TableCell align="right">{displayAmount(txn.price)}</TableCell>
            <TableCell align="right">{displayAmount(txn.total)}</TableCell>
            <TableCell align="right">{displayAmount(txn.balance)}</TableCell>
            <TableCell align="right">{displayAmount(txn.purchasePrice)}</TableCell>
            <TableCell align="right" className={paintResult(txn.result)}>{displayAmount(txn.result)}</TableCell>
            <TableCell padding="checkbox" className="no-print">
              <Grid container wrap="nowrap">
                <Grid item>
                  <IconButton onClick={() => remove(i)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item>    
                  <IconButton onClick={() => copy(txn)}>
                    <FileCopyIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}

export default TransactionsTable;
