import { differenceInYears, parseISO } from 'date-fns';
import Decimal from 'decimal.js';
import useAppData from '../../util/useAppData';

export const Transaction = {
  BUY: 'BUY',
  SELL: 'SELL',
  REWARD: 'REWARD',
}

class TransactionQueue {
  constructor() {
    this.queue = [];
    this.balance = new Decimal(0);
  }

  add(timestamp, amount, price) {
    this.queue.push({ 
      amount: new Decimal(amount),
      price: new Decimal(price),
      timestamp,
    });
    this.balance = this.balance.add(amount);
  }

  remove(saleTimestamp, amount, price) {
    let remainder = new Decimal(amount);
    const salePrice = new Decimal(price);

    if (remainder.gt(this.balance)) {
      throw new RangeError('Insufficient balance');
    }

    this.balance = this.balance.sub(amount);
    let purchasePrice = new Decimal(0);

    while (remainder.gt(0)) {
      let { amount, price: actualPurchasePrice, timestamp } = this.queue.shift();

      const purchaseDate = parseISO(timestamp);
      const saleDate = parseISO(saleTimestamp);

      const presumptivePurchasePrice = (differenceInYears(saleDate, purchaseDate) < 10)
        ? salePrice.mul(0.2)
        : salePrice.mul(0.4)

      const price = actualPurchasePrice.gt(presumptivePurchasePrice)
        ? actualPurchasePrice
        : presumptivePurchasePrice;

      if (amount.gt(remainder)) {
        this.queue.unshift({
          amount: amount.sub(remainder),
          price: actualPurchasePrice,
          timestamp,
        });
        amount = remainder;
      }

      remainder = remainder.sub(amount);
      purchasePrice = purchasePrice.add(amount.mul(price));
    }

    return purchasePrice;
  }
}

const sortByTime = (a, b) => a.timestamp.localeCompare(b.timestamp);

const processTransactions = txns => {
  const queues = {};

  return txns.sort(sortByTime).map(txn => {
    const { currency, type, amount, price, timestamp } = txn;

    if (!Object.prototype.hasOwnProperty.call(queues, currency)) {
      queues[currency] = new TransactionQueue();
    }

    const { [currency]: queue } = queues;

    switch (type) {
      case Transaction.BUY: {
        queue.add(timestamp, amount, price);

        return {
          ...txn,
          balance: queue.balance.toString(),
          total: new Decimal(amount).mul(price).toString(),
          result: '',
          purchasePrice: '',
        }
      }
      case Transaction.REWARD: {
        queue.add(amount, 0);

        return {
          ...txn,
          balance: queue.balance.toString(),
          total: '0',
          result: '',
          purchasePrice: '',
        }
      }
      case Transaction.SELL: {
        const salePrice = new Decimal(price).mul(amount);
        const purchasePrice = queue.remove(timestamp, amount, price);
        const result = salePrice.sub(purchasePrice);

        return {
          ...txn,
          balance: queue.balance.toString(),
          total: salePrice.toString(),
          result: result.toString(),
          purchasePrice: purchasePrice.toString(),
        };
      }
      default: {
        throw new RangeError(`Unknown transaction type: ${type}`);
      }
    }
  });
}

const useTransactionRegistry = () => {
  const { transactions, setTransactions: setTransactionState } = useAppData();

  const setTransactions = fn => setTransactionState(
    txns => processTransactions(typeof fn === 'function' ? fn(txns) : fn),
  );

  const add = txn => setTransactions(txns => [...txns, txn]);
  const remove = index => setTransactions(txns => [...txns.slice(0, index), ...txns.slice(index + 1)]);
  
  const persist = () => {
    throw new Error('ERROR');
  }

  return {
    add,
    remove,
    persist,
    transactions,
  };
}

export default useTransactionRegistry;
