import React, { forwardRef } from 'react';
import { KeyboardDateTimePicker as MuiDatePicker } from '@material-ui/pickers';
import { useScopedController } from 'react-hook-smartform';
import { formatISO, isValid, parseISO } from 'date-fns';

const parseDateString = dateString => {
  const date = parseISO(dateString);
  return isValid(date) ? date : null;
}

const DatePicker = forwardRef(({ name: fieldName, rules, ...props }, ref) => {
  const {
    field: { value: dateString, onChange },
  } = useScopedController({
    name: fieldName,
    rules,
    defaultValue: null,
    emptyValue: null,
  });

  const handleChange = date => isValid(date) && onChange(formatISO(date));
  const date = parseDateString(dateString);

  return (
    <MuiDatePicker
      variant="inline"
      inputVariant="outlined"
      autoOk
      ampm={false}
      fullWidth
      value={date}
      onChange={handleChange}
      disableToolbar
      {...props}
    />
  );
});

export default DatePicker;
