import React from 'react';
import {
  AppBar,
  createTheme,
  CssBaseline,
  Grid,
  makeStyles,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FifoCounter from './components/FifoCounter';
import AppDataProvider from './components/AppDataProvider/AppDataProvider';
import TransactionsProvider from './components/TransactionsProvider';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
  },
  toolbar: {
    zIndex: 1,
  },
})

const theme = createTheme({});

const App = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AppDataProvider>
        <TransactionsProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container direction="column" wrap="nowrap">
              <Grid item className={clsx(classes.toolbar, 'no-print')}>
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6">
                      {t('app.title')}
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Grid>
              <Grid item className={classes.content}>
                <FifoCounter />
              </Grid>
            </Grid>
          </ThemeProvider>
        </TransactionsProvider>
      </AppDataProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
