import AddIcon from '@material-ui/icons/Add';
import { Box, Grid, MenuItem, TextField as MuiTextField } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-hook-smartform';
import useAppData from '../../util/useAppData';
import Select from '../../fields/Select';
import useTransactions from '../../util/useTransactions';
import ComboBox from '../../fields/ComboBox';
import TextField from '../../fields/TextField';
import DateTimePicker from '../../fields/DateTimePicker';
import { DATETIME_DISPLAY_FORMAT } from '../../util/config';
import SubmitFab from '../../fields/SubmitFab';
import { Transaction } from '../TransactionsProvider/useTransactionRegistry';
import { useTranslation } from 'react-i18next';
import PriceField from '../../fields/PriceField';

const TransactionForm = ({ defaultValue }) => {
  const { accounts, currencies } = useAppData();
  const { add } = useTransactions();
  const { t } = useTranslation();

  const handleSubmit = (value, _, { reset }) => {
    const {
      account,
      currency,
      type,
    } = value;

    reset({
      account,
      currency,
      type,
    });

    return add(value);
  }

  return (
    <Box margin={2}>
      <Form onSubmit={handleSubmit} defaultValue={defaultValue}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select name="account" label={t('transactions.account')}>
              {accounts.map(({ name, value }) => (
                <MenuItem value={value} key={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <ComboBox
              name="currency"
              label={t('transactions.currency')}
              options={currencies}
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              name="timestamp"
              label={t('transactions.timestamp')}
              format={DATETIME_DISPLAY_FORMAT}
            />
          </Grid>
          <Grid item xs={12}>
            <Select name="type" label={t('transactions.type')}>
              <MenuItem value={Transaction.BUY}>
                {t('types.BUY')}
              </MenuItem>
              <MenuItem value={Transaction.SELL}>
                {t('types.SELL')}
              </MenuItem>
              <MenuItem value={Transaction.REWARD}>
                {t('types.REWARD')}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="amount"
              label={t('transactions.amount')}
            />
          </Grid>
          <Grid item xs={12}>
            <PriceField
              name="price"
              label={t('transactions.price')}
              totalLabel={t('transactions.total')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="note"
              label={t('transactions.note')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="reference"
              label={t('transactions.reference')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>    
                <SubmitFab
                  icon={AddIcon}
                  size="small"
                  disableIfClean={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
}

export default TransactionForm;