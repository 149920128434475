import { useFormState } from 'react-hook-form';
import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { CircularProgress, Fab } from '@material-ui/core';

const SubmitFab = ({ icon, disableIfClean = true, ...props }) => {
  const { isSubmitting, isValid, isDirty } = useFormState();
  const Icon = icon || SaveIcon;

  return (
    <Fab
      type="submit"
      color="secondary"
      disabled={isSubmitting || !isValid || (disableIfClean && !isDirty)}
      {...props}
    >
      {isSubmitting ? <CircularProgress color="inherit" /> : <Icon />}
    </Fab>
  );
};

export default SubmitFab;
