import { useScopedController } from 'react-hook-smartform';
import Decimal from 'decimal.js';
import MuiTextField from '@material-ui/core/TextField';
import React, { forwardRef } from 'react';
import { useWatch } from 'react-hook-form';
import { Grid } from '@material-ui/core';

const PriceField = forwardRef((props, ref) => {
  const {
    name: fieldName,
    helperText,
    rules,
    label,
    totalLabel,
  } = props;

  const { field: { value, onChange, ...other }, fieldState } = useScopedController({
    name: fieldName,
    rules,
  });

  const required = !!(rules || {}).required;
  const { invalid, error } = fieldState;
  
  const amount = useWatch({ name: 'amount' }) || null;
  const total = amount && value ? (new Decimal(amount)).mul(value).toString() : null;

  const onTotalChange = ({ target: { value: newTotal }}) => {
    try {
      const newPrice = new Decimal(newTotal).div(amount).toString();
      onChange(newPrice);
    } catch (e) {
      // Fail.
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MuiTextField
          ref={ref}
          fullWidth
          variant="outlined"
          required={required}
          error={invalid}
          helperText={
            invalid
              ? error.message
              : helperText
          }
          label={label}
          value={value}
          onChange={onChange}
          {...other}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiTextField
          fullWidth
          variant="outlined"
          label={totalLabel}
          onChange={onTotalChange}
          value={total}
        />
      </Grid>
    </Grid>
  );
});

export default PriceField;
