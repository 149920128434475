import React, { createContext, useState, useCallback, useEffect } from 'react';

export const AppDataContext = createContext();

const EMPTY_ARRAY = Object.freeze([]);
const LOCALSTORE_KEY = 'localstate';

const useScopedSetter = (key, setState, defaultValue = EMPTY_ARRAY) => useCallback(fn => setState(({ [key]: scopedState = defaultValue, ...other }) => ({
  ...other,
  [key]: typeof fn === 'function' ? fn(scopedState) : fn,
})), [key, setState, defaultValue]);

const AppDataProvider = ({ children }) => {
  const [state, setActualState] = useState({});

  const setState = useCallback(fn => setActualState(current => {
    const next = typeof fn === 'function' ? fn(current) : fn;
    localStorage.setItem(LOCALSTORE_KEY, JSON.stringify(next));
    return next;
  }), []);

  useEffect(() => {
    const data = localStorage.getItem(LOCALSTORE_KEY);
    if (data) {
      setActualState(JSON.parse(data));
    }
  }, []);

  const {
    accounts = [],
    currencies = [],
    transactions = [],
  } = state;

  const setAccounts = useScopedSetter('accounts', setState);
  const setCurrencies = useScopedSetter('currencies', setState);
  const setTransactions = useScopedSetter('transactions', setState);

  const context = {
    accounts,
    setAccounts,
    currencies,
    setCurrencies,
    transactions,
    setTransactions,
    setState,
  };

  return (
    <AppDataContext.Provider value={context}>
      {children}
    </AppDataContext.Provider>
  );
};

export default AppDataProvider;
