import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';

const whitelist = ['en'];

const resources = {
  en: { ui: en },
};

const [fallbackLng] = whitelist;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng,
    whitelist,
    resources,
    defaultNS: 'ui',
    fallbackNS: 'ui',
    returnEmptyString: false,
    detection: {
      checkWhitelist: true,
    },
  });

export default i18n;
