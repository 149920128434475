import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useScopedController } from 'react-hook-smartform';

const ComboBox = ({
  name: fieldName,
  rules,
  options,
  label,
  ...props
}) => {
  const { field: { value, onChange } } = useScopedController({
    name: fieldName,
    rules,
  });

  const [values, getOptionLabel] = useMemo(() => {
    const valueToName = options.reduce(
      (result, { name, value }) => ({ ...result, [value]: name }),
      {},
    );
    return [
      options.map(({ value }) => value),
      value => valueToName[value] || '',
    ];
  }, [options]);

  return (
    <Autocomplete
      options={values}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      {...props}
      value={value}
      onChange={(_, value) => onChange(value)}
    />
  );
}

export default ComboBox;
