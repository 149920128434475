import React, { createContext } from 'react';
import useTransactionRegistry from './useTransactionRegistry';

export const TransactionsContext = createContext();

const TransactionsProvider = ({ children }) => {
  const context = useTransactionRegistry();

  return (
    <TransactionsContext.Provider value={context}>
      {children}
    </TransactionsContext.Provider>
  );
}

export default TransactionsProvider;
