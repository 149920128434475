import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { Form } from 'react-hook-smartform';
import useAppData from '../../util/useAppData';
import TextField from '../../fields/TextField';
import SubmitFab from '../../fields/SubmitFab';
import { useTranslation } from 'react-i18next';

const AccountSettings = () => {
  const { accounts, setAccounts } = useAppData();
  const { t } = useTranslation();
  const add = currency => setAccounts(as => [...as, currency]);
  const remove = index => setAccounts(as => [...as.slice(0, index), ...as.slice(index + 1)]);

  return (
    <Box margin={2}>
      <Grid container>
        <Grid item xs={12}>
          <Form onSubmit={add}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  name="value"
                  label={t('accounts.value')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={t('accounts.name')}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>    
                    <SubmitFab
                      icon={AddIcon}
                      size="small"
                      disableIfClean={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={12}>
          <List>
            {accounts.map(({ name, value }, i) => (
              <ListItem key={value}>
                <ListItemText primary={name} secondary={value} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => remove(i)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AccountSettings;